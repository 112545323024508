import styled from 'styled-components'

export const StyledCertificationsContainer = styled.div`
  padding: 5%;

  h1 {
    text-align: center;
  }
`
export const StyledCertificationBoxItems = styled.div`
  display: flex;
  overflow-x: auto;

  @media all and (max-width: 480px) {
    display: grid;
    justify-content: center;
  }
`
