import styled from 'styled-components'

export const StyledMedologyComponent = styled.div`
  padding: 5%;
  color: white;
  background-color: #bed300;

  h1 {
    text-align: center;
  }

  h4 {
    text-align: center;
  }
`
export const StyledImgBoxContent = styled.div``
