import { Button, Col, Image, Row } from 'antd'
import React from 'react'
import { StyledTitleH1, StyledTitleH4 } from '../../styles/styledComponets'
import {
  StyledButtonContent,
  StyledImgContent,
  StyledSlideComponent
} from './styledComponents'
import slideOne from '../../assets/slider/SliderXpika.png'
import Counter from '../counter'

const Slider: React.FC = () => {
  return (
    <StyledSlideComponent>
      <Row justify="space-around" align="middle">
        <Col xs={24} sm={24} md={24} lg={12}>
          <StyledTitleH1>
            Especialistas en la <br /> enseñanza del Inglés
          </StyledTitleH1>
          <StyledTitleH4>
            Aprendizaje desde cero, Perfeccionamiento en la Pronunciación,
            Certificaciones Americanas, Brítanicas y más.
          </StyledTitleH4>
          <StyledButtonContent>
            <Button type="primary" size="large">
              MÁS INFORMACIÓN
            </Button>
          </StyledButtonContent>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: 'center' }}>
          <StyledImgContent>
            <Image src={slideOne} preview={false}></Image>
          </StyledImgContent>
        </Col>
      </Row>
      <Counter />
    </StyledSlideComponent>
  )
}

export default Slider
