import { ThemeConfig } from 'antd/es/config-provider/context'

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#BED300'
  },
  components: {
    Button: {
      borderRadius: 100,
      borderRadiusLG: 100,
      fontSize: 14,
      controlHeight: 50,
      controlHeightLG: 50
    }
  }
}
