import React from 'react'
import CertificationCard from '../../components/certificationCard'
import { StyledTitleH1 } from '../../styles/styledComponets'
import {
  StyledCertificationBoxItems,
  StyledCertificationsContainer
} from './styledComponents'

const CertificationsContainer: React.FC = () => {
  return (
    <StyledCertificationsContainer>
      <StyledTitleH1>Certificaciones</StyledTitleH1>
      <StyledCertificationBoxItems>
        <CertificationCard />
        <CertificationCard />
        <CertificationCard />
        <CertificationCard />
      </StyledCertificationBoxItems>
    </StyledCertificationsContainer>
  )
}

export default CertificationsContainer
