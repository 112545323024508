import React from 'react'
import TestimonyCard from '../../components/testimonyCard'
import { StyledTitleH1 } from '../../styles/styledComponets'
import {
  StyledTestimoniesContainer,
  StyledTestimoniesContent
} from './styledComponent'

const TestimoniesContainer: React.FC = () => {
  return (
    <StyledTestimoniesContainer>
      <StyledTitleH1>Testimonios</StyledTitleH1>
      <StyledTestimoniesContent>
        <TestimonyCard />
        <TestimonyCard />
        <TestimonyCard />
        <TestimonyCard />
        <TestimonyCard />
        <TestimonyCard />
        <TestimonyCard />
      </StyledTestimoniesContent>
    </StyledTestimoniesContainer>
  )
}

export default TestimoniesContainer
