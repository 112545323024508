import React from 'react'
import { StyledTitleH1, StyledTitleH4 } from '../../styles/styledComponets'
import { StyledCertificationContent } from './styledComponents'

const CertificationCard: React.FC = () => {
  return (
    <StyledCertificationContent>
      <StyledTitleH1>Preliminary English Test PET</StyledTitleH1>
      <StyledTitleH4>
        6 meses <br />
        10 alumnos máximo
      </StyledTitleH4>
    </StyledCertificationContent>
  )
}

export default CertificationCard
