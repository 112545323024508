import styled from 'styled-components'
export const StyledTestimoniesContainer = styled.div`
  padding: 2%;
  background-color: #f9f9f9;

  h1 {
    text-align: center;
  }
`
export const StyledTestimoniesContent = styled.div`
  display: flex;
  overflow-x: auto;
`
