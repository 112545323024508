import styled from 'styled-components'

export const StyledTestimonyContent = styled.div`
  background-color: #f4f5f4;
  padding: 5%;
  width: 320px;
  border-radius: 10px;
  margin: 10px;
  flex-shrink: 0;
`
export const StyledTestimonyAuthor = styled.p`
  font-weight: 500;
  font-size: 14px;
`

export const StyledTestimonyText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
`

export const StyledTestimonyDate = styled.p`
  font-weight: 500;
  font-size: 12px;
  text-decoration: underline;
  font-style: italic;
`
