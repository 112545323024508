import React from 'react'
import CourseCard from '../../components/courseCard'
import { StyledTitleH1 } from '../../styles/styledComponets'
import { StyledCoursesBox, StyledCoursesContainer } from './styledComponents'

const CoursesContainer: React.FC = () => {
  return (
    <StyledCoursesContainer>
      <StyledTitleH1>Nuestros Cursos</StyledTitleH1>
      <StyledCoursesBox>
        <CourseCard />
        <CourseCard />
        <CourseCard />
        <CourseCard />
        <CourseCard />
        <CourseCard />
      </StyledCoursesBox>
    </StyledCoursesContainer>
  )
}

export default CoursesContainer
