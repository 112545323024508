import styled from 'styled-components'

export const StyledSlideComponent = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  min-height: 580px;
  background-color: rgba(190, 211, 0, 0.21);
  padding: 0 10%;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 2% 5%;
  }

  @media all and (max-width: 480px) {
    padding: 2% 5%;
    flex-direction: column;
  }
`

export const StyledButtonContent = styled.div`
  @media all and (min-width: 768px) and (max-width: 1023px) {
    text-align: center;
  }

  @media all and (max-width: 480px) {
    text-align: center;
  }
`

export const StyledImgContent = styled.div`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    && {
      img {
        height: 250px;
        width: 270px;
        margin: 25px 0;
      }
    }
  }

  @media all and (max-width: 480px) {
    && {
      img {
        height: 200px;
        width: 220px;
        margin: 35px 0;
      }
    }
  }
`
