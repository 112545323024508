import React from 'react'
import {
  StyledTestimonyAuthor,
  StyledTestimonyContent,
  StyledTestimonyDate,
  StyledTestimonyText
} from './styledComponents'

const TestimonyCard: React.FC = () => {
  return (
    <StyledTestimonyContent>
      <StyledTestimonyText>
        " Tengo 60 años, soy secretaria y mi sueño siempre fue estudiar Inglés,
        al inscribirme a IMISPICA ahora estoy hablando el idioma. Gracias
        profesores Gaby y Eduardo. Sueño Cumplido "
      </StyledTestimonyText>
      <StyledTestimonyAuthor>Georgina López Martínez</StyledTestimonyAuthor>
      <StyledTestimonyDate>21/01/23</StyledTestimonyDate>
    </StyledTestimonyContent>
  )
}

export default TestimonyCard
