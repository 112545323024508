import styled from 'styled-components'

export const StyledCoursesContainer = styled.div`
  padding: 5%;
  background-color: #f9f9f9;
  h1 {
    text-align: center;
  }
`
export const StyledCoursesBox = styled.div`
  display: flex;
  overflow-x: auto;
`
