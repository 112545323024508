import { useFirstPrismicDocument } from '@prismicio/react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import NavigationBar from '../../types/navigation'
import LogoXpika from './../../assets/Logo.png'
import Menu from './../../assets//menus.png'

import {
  NavigationContainer,
  UlContainer,
  LiContainer,
  AContainer,
  LogoContainer,
  ImgContainer,
  MenuContainer,
  ImgMenuContainer,
  UlMobileContainer
} from './styledComponents'
import { useWindowSize } from '../../hooks/useDimensions'

const Header: React.FC = () => {
  const [prismicData] = useFirstPrismicDocument()
  const [pathSelected, setPathSelected] = useState('#home')
  const [hiddenMenu, setHiddenMenu] = useState(true)
  const size = useWindowSize()
  const location = useLocation()
  const [menu, setMenu] = useState<NavigationBar.Menu[]>()

  useEffect(() => {
    if (prismicData) {
      setMenu(prismicData.data.menu_items)
      setPathSelected(location.hash)
    }
  }, [prismicData, location])

  const handleOnclickItem = (e: any) => {
    const path = e.target.href.split('#')[1]
    setHiddenMenu(!hiddenMenu)
    setPathSelected(`#${path}`)
  }

  const menuItems = () => {
    let menuRender: any = []
    menu?.forEach((item: NavigationBar.Menu) => {
      menuRender.push(
        <LiContainer
          isMobile={size.width < 768 ? true : false}
          key={item.id}
          selected={item.path === pathSelected ? true : false}
          onClick={handleOnclickItem}
        >
          <AContainer href={`${window.location.origin}/${item.path}`}>
            {item.title}
          </AContainer>
        </LiContainer>
      )
    })
    return menuRender
  }

  return (
    <React.Fragment>
      <NavigationContainer>
        <LogoContainer>
          <ImgContainer src={LogoXpika}></ImgContainer>
        </LogoContainer>
        <UlContainer>{menuItems()}</UlContainer>
        <MenuContainer>
          <ImgMenuContainer
            src={Menu}
            onClick={() => setHiddenMenu(!hiddenMenu)}
          ></ImgMenuContainer>
        </MenuContainer>
      </NavigationContainer>
      <UlMobileContainer hidden={hiddenMenu}>{menuItems()}</UlMobileContainer>
    </React.Fragment>
  )
}

export default Header
