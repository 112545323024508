import { Image, Row } from 'antd'
import React from 'react'
import { StyledTitleH1, StyledTitleH4 } from '../../styles/styledComponets'
import {
  StyledImgAbilityBoxContent,
  StyledImgAbilityContent
} from '../aboutUs/styledComponents'
import { StyledMedologyComponent } from './styledComponents'
import Brain from '../../assets/metodology/Brain.png'
import Force from '../../assets/metodology/Force.png'
import Frindship from '../../assets/metodology/Friendship.png'
import Idea from '../../assets/metodology/Idea.png'
import Success from '../../assets/metodology/Success.png'

const Medology: React.FC = () => {
  return (
    <StyledMedologyComponent>
      <StyledTitleH1>Nuestra Medología</StyledTitleH1>
      <StyledTitleH4>
        Xpika-Two es un instituto especializado en el idioma inglés y
        perfeccionamiento, además, desarrolla tu nivel académico e intelectual
        donde el perfil educativo se basa en
      </StyledTitleH4>
      <Row>
        <StyledImgAbilityBoxContent>
          <StyledImgAbilityContent alingItems="self-start">
            <Image src={Brain} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="flex-end">
            <Image src={Force} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="self-start">
            <Image src={Frindship} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="flex-end">
            <Image src={Idea} preview={false} />
          </StyledImgAbilityContent>
          <StyledImgAbilityContent alingItems="self-start">
            <Image src={Success} preview={false} />
          </StyledImgAbilityContent>
        </StyledImgAbilityBoxContent>
      </Row>
    </StyledMedologyComponent>
  )
}

export default Medology
