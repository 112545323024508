import React from 'react'
import Asks from '../../components/asks'
import FormContact from '../../components/formContact'
import { StyledContactContainer } from './styledComponents'

const ContactContainer: React.FC = () => {
  return (
    <StyledContactContainer>
      <Asks />
      <FormContact />
    </StyledContactContainer>
  )
}

export default ContactContainer
