import React from 'react'
import { StyledFormContactContent } from './styledComponents'
import { Button, Form, Input } from 'antd'

/* eslint-disable no-template-curly-in-string */

const FormContact: React.FC = () => {
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  }
  const onFinish = (values: any) => {
    console.log(values)
  }

  return (
    <StyledFormContactContent>
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={['user', 'name']}
          label="Nombre"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['user', 'email']}
          label="Correo electrónico"
          rules={[{ type: 'email', required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['user', 'introduction']}
          label="Mensaje"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar mensaje
          </Button>
        </Form.Item>
      </Form>
    </StyledFormContactContent>
  )
}

export default FormContact
