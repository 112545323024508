import React from 'react'
import LogoXpika from '../../assets/history/LogoXpika.png'
import Circles from '../../assets/history/Circles.png'
import {
  StyledHistoryComponent,
  StyledImgHistoryBox,
  StyledTextHistoryContent,
  StyledValuesHistoryBox
} from './styledComponents'
import { Col, Image, Row } from 'antd'
import {
  StyledTitleH1,
  StyledTitleH3,
  StyledTitleH4
} from '../../styles/styledComponets'

const History: React.FC = () => {
  return (
    <StyledHistoryComponent>
      <StyledImgHistoryBox>
        <Image src={Circles} preview={false} />
        <Image src={LogoXpika} preview={false} />
        <Image
          src={Circles}
          preview={false}
          style={{ transform: 'scaleX(-1)' }}
        />
      </StyledImgHistoryBox>
      <Row>
        <Col xs={24} sm={24} md={8} lg={8}>
          <StyledTextHistoryContent>
            <StyledTitleH1>Misión</StyledTitleH1>
            <StyledTitleH4>
              Contribuir a que nuestros estudiantes alcancen sus metas
              académicas y personales a través de nuestra experiencia en la
              enseñanza del idioma en una mejora continua.
            </StyledTitleH4>
          </StyledTextHistoryContent>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}></Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <StyledTextHistoryContent>
            <StyledTitleH1>Visión</StyledTitleH1>
            <StyledTitleH4>
              Seguir siendo una institución competitiva en la enseñanza de
              idiomas y comprometida con la excelencia académica y la calidad en
              el servicio en área latinoamericana.
            </StyledTitleH4>
          </StyledTextHistoryContent>
        </Col>
      </Row>
      <StyledValuesHistoryBox>
        <StyledTitleH3>Compromiso</StyledTitleH3>
        <StyledTitleH3>Armonía</StyledTitleH3>
        <StyledTitleH3>Innovación</StyledTitleH3>
        <StyledTitleH3>Confianza</StyledTitleH3>
        <StyledTitleH3>Trabajo en Equipo</StyledTitleH3>
      </StyledValuesHistoryBox>
    </StyledHistoryComponent>
  )
}

export default History
