import React from 'react'
import { StyledTitleH4 } from '../../styles/styledComponets'
import { StyledFooterContent } from './styledComponents'

const Footer: React.FC = () => {
  return (
    <StyledFooterContent>
      <StyledTitleH4>
        Copyright 2023 © XPIKA desarrollado por Inovace DS
      </StyledTitleH4>
    </StyledFooterContent>
  )
}

export default Footer
