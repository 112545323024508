import { Col, Row } from 'antd'
import React from 'react'
import { StyledTitleH2, StyledTitleH5 } from '../../styles/styledComponets'
import { StyledCounterBox } from './styledComponents'

const Counter: React.FC = () => {
  return (
    <Row justify="space-around" align="middle">
      <Col xs={24} sm={24} md={8} lg={8}>
        <StyledCounterBox>
          <StyledTitleH2>20, 909</StyledTitleH2>
          <StyledTitleH5>Alumnos inscritos en nuestros cursos</StyledTitleH5>
        </StyledCounterBox>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <StyledCounterBox>
          <StyledTitleH2>4 +</StyledTitleH2>
          <StyledTitleH5>
            Niveles de nuestro programa de aprendizaje
          </StyledTitleH5>
        </StyledCounterBox>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <StyledCounterBox>
          <StyledTitleH2>99%</StyledTitleH2>
          <StyledTitleH5>De nuestros alumnos certificados</StyledTitleH5>
        </StyledCounterBox>
      </Col>
    </Row>
  )
}

export default Counter
