import styled from 'styled-components'

interface menuItem {
  selected: boolean
  isMobile: boolean
}

export const NavigationContainer = styled.div`
  display: flex;
  height: 75px;
  background-color: rgba(190, 211, 0, 0.21);
`

export const LogoContainer = styled.div`
  margin: 15px;
  width: 30%;
  text-align: center;
`
export const ImgContainer = styled.img`
  height: 100%;
`

export const MenuContainer = styled.div`
  margin: 15px;
  width: 10%;
  text-align: center;
  display: none;

  @media all and (min-width: 480px) and (max-width: 768px) {
    display: initial;
    width: 70%;
    text-align: right;
  }

  @media all and (max-width: 480px) {
    display: initial;
    width: 70%;
    text-align: right;
  }
`
export const ImgMenuContainer = styled.img`
  height: 100%;
  cursor: pointer;
`

export const UlContainer = styled.ul`
  display: flex;
  align-content: center;
  list-style: none;
  width: 60%;
  justify-content: space-evenly;

  @media all and (min-width: 480px) and (max-width: 768px) {
    display: none;
  }

  @media all and (max-width: 480px) {
    display: none;
  }
`

export const LiContainer = styled.li<menuItem>`
  float: left;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isMobile ? '15px' : '5px')};
  border-bottom: ${(props) =>
    props.selected
      ? (props) => (props.isMobile ? 'none' : '2px solid black')
      : '2px solid transparent'};
`

export const AContainer = styled.a`
  text-decoration: none;
  color: black;
  text-align: center;
  width: 100%;
`

export const UlMobileContainer = styled.ul`
  width: 100%;
  background-color: rgba(190, 211, 0, 0.21);
  height: 320px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-inline-start: 0px;
`
