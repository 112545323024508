import styled from 'styled-components'

export const StyledCertificationContent = styled.div`
  width: 400px;
  background-color: #bed300;
  border-radius: 10px;
  height: 200px;
  padding: 10px 20px;
  margin: 10px;
  flex-shrink: 0;
  box-shadow: 0px 2px 13px 3px rgba(224, 224, 224, 1);
  -webkit-box-shadow: 0px 2px 13px 3px rgba(224, 224, 224, 1);
  -moz-box-shadow: 0px 2px 13px 3px rgba(224, 224, 224, 1);

  h1 {
    color: white;
    font-size: 30px;
    line-height: 50px;
  }

  h4 {
    color: white;
    text-align: center;
    line-height: 20px;
  }

  @media all and (max-width: 480px) {
    width: 250px;
    height: 150px;

    h1 {
      color: white;
      font-size: 20px;
      line-height: 30px;
    }
  }
`
