import styled from 'styled-components'

export const StyledCounterBox = styled.div`
  width: 100%;
  padding: 20px;
  text-align: center;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 480px) {
    margin: 0;
  }
`

export const StyledCirclePoint = styled.div`
  position: absolute;
  left: 40px;
  width: 20px;
  height: 20px;
  background-color: #1f4398;
  border-radius: 10px;
`
