import React from 'react'
import AboutUs from '../../components/aboutUs'
import Header from '../../components/header'
import History from '../../components/history'
import Medology from '../../components/metodology'
import Slider from '../../components/slider'
import CertificationsContainer from '../../containers/certificationsContainer'
import ContactContainer from '../../containers/contactContainer'
import CoursesContainer from '../../containers/coursesContainer'
import FooterContainer from '../../containers/footerContainer'
import TestimoniesContainer from '../../containers/testimoniesContainer'
import BaseStyles from '../../styles/base'

const HomePage: React.FC = () => {
  return (
    <div>
      <BaseStyles />
      <Header />
      <Slider />
      <AboutUs />
      <History />
      <Medology />
      <CoursesContainer />
      <CertificationsContainer />
      <TestimoniesContainer />
      <ContactContainer />
      <FooterContainer />
    </div>
  )
}

export default HomePage
