import { PrismicProvider } from '@prismicio/react'
import { ConfigProvider } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { client } from './config/prismic'
import HomePage from './pages/home'
import reportWebVitals from './reportWebVitals'
import { theme } from './styles/customThemeAntd'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <BrowserRouter>
        <PrismicProvider client={client}>
          <HomePage />
        </PrismicProvider>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
