import styled from 'styled-components'

export const StyledCourseCardContent = styled.div`
  width: 320px;
  height: 450px;
  border-radius: 20px;
  margin: 10px;
  flex-shrink: 0;
  box-shadow: 0px 2px 13px 3px rgba(224, 224, 224, 1);
  -webkit-box-shadow: 0px 2px 13px 3px rgba(224, 224, 224, 1);
  -moz-box-shadow: 0px 2px 13px 3px rgba(224, 224, 224, 1);

  @media all and (min-width: 1024px) and (max-width: 1280px) {
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    width: 250px;
  }
`
export const StyledCourseTitleBoxContent = styled.div`
  background-color: #df9a41;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  h2 {
    color: white;
    text-decoration: none;
    margin: 0;
  }

  @media all and (max-width: 480px) {
    height: 30%;
  }
`
export const StyledCourseInformationContent = styled.div`
  padding: 5% 8%;

  h3 {
    text-align: left;
  }
`

export const StyledCourseTagContent = styled.div`
  display: flex;
  margin: 10px 0;
`
export const StyledCourseTagItem = styled.div`
  background-color: #1f4398;
  padding: 5px;
  color: white;
  font-weight: 200;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 12px;
`

export const StyledCourseDescription = styled.p`
  color: #999999;
  line-height: 22px;
  font-size: 14px;
  text-align: left;
`

export const StyledCourseHR = styled.hr`
  margin: 20px 0;
`

export const StyledCoursePriceContent = styled.div`
  display: flex;
  align-items: baseline;
`
export const StyledCoursePriceCurrency = styled.p`
  margin: 0;
  font-weight: 600;
`
export const StyledCoursePriceNumber = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 40px;
`
export const StyledCoursePriceCents = styled.p`
  margin: 0;
  font-weight: 600;
`
