import React from 'react'
import { StyledTitleH2, StyledTitleH3 } from '../../styles/styledComponets'
import {
  StyledCourseCardContent,
  StyledCourseDescription,
  StyledCourseHR,
  StyledCourseInformationContent,
  StyledCoursePriceCents,
  StyledCoursePriceContent,
  StyledCoursePriceCurrency,
  StyledCoursePriceNumber,
  StyledCourseTagContent,
  StyledCourseTagItem,
  StyledCourseTitleBoxContent
} from './sytledComponents'

const CourseCard: React.FC = () => {
  return (
    <StyledCourseCardContent>
      <StyledCourseTitleBoxContent>
        <StyledTitleH2>Early birds</StyledTitleH2>
      </StyledCourseTitleBoxContent>
      <StyledCourseInformationContent>
        <StyledTitleH3>EARLY Birds Dominical</StyledTitleH3>
        <StyledCourseTagContent>
          <StyledCourseTagItem>TEENS</StyledCourseTagItem>
          <StyledCourseTagItem>ADULTS</StyledCourseTagItem>
        </StyledCourseTagContent>
        <StyledCourseDescription>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </StyledCourseDescription>
        <StyledCourseHR />
        <StyledCoursePriceContent>
          <StyledCoursePriceCurrency>$</StyledCoursePriceCurrency>
          <StyledCoursePriceNumber>700</StyledCoursePriceNumber>
          <StyledCoursePriceCents>.00</StyledCoursePriceCents>
        </StyledCoursePriceContent>
      </StyledCourseInformationContent>
    </StyledCourseCardContent>
  )
}

export default CourseCard
