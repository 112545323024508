import styled from 'styled-components'
export const StyledHistoryComponent = styled.div`
  padding: 5%;
  background-color: #001b59;
`
export const StyledImgHistoryBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
`

export const StyledTextHistoryContent = styled.div`
  margin: 30px 0;
  color: white;

  h1 {
    text-align: center;
  }

  h4 {
    text-align: center;
  }
`

export const StyledValuesHistoryBox = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 30px;
  }

  @media all and (min-width: 1024px) and (max-width: 1280px) {
  }

  @media all and (min-width: 768px) and (max-width: 1023px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
    display: initial;
    text-align: center;
  }

  @media all and (max-width: 480px) {
    display: initial;
    text-align: center;
  }
`
