// Packages
import { normalize } from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import './fonts.css'
import 'antd/dist/reset.css'
// Assets
import { ThemeType } from './theme'

const BaseStyles = createGlobalStyle<{ theme: ThemeType }>`${({ theme }) => `
  ${normalize}

  *, *::before, *::after {
    box-sizing: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  html {
    font-family: 'Heebo', sans-serif;
    box-sizing: border-box;
    line-height: 1;
  }
`}`

export default BaseStyles
